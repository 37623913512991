/**
 * see https://stackoverflow.com/questions/8902608/how-to-bind-a-checkbox-to-the-inverse-of-a-value
 */

import * as ko from "knockout"

ko.bindingHandlers.checkedInverse = {
    init: function(element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
        if(!ko.isObservable(valueAccessor())) {
            throw new Error('checkedInverse binding: value must be an observable');
        }
        const value = valueAccessor();
        const interceptor = ko.computed({
            read: function() {
                return !value();
            },
            write: function(newValue) {
                value(!newValue);
            },
            disposeWhenNodeIsRemoved: element
        });

        const newValueAccessor = function() { return interceptor; };

        //keep a reference, so we can use in update function
        ko.utils.domData.set(element, "newValueAccessor", newValueAccessor);

        //call the real checked binding's init with the interceptor instead of our real observable
        ko.bindingHandlers.checked.init(element, newValueAccessor, allBindingsAccessor);
    }
};