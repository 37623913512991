import * as $ from "jquery";

/**
 * Includes an external resource (markup) into the DOM.
 * @param element an DOM element where the loaded resource should be included
 * @param resourceUrl the url of the external resource
 * @param replace if true, replaces the element, appends it otherwise
 * @returns a promise that succeeds when the resource has been added to the DOM
 */
export const include = function (element: String | Element, resourceUrl: string, replace: boolean = false): Promise<void> {
    return fetch(resourceUrl, {
        redirect: 'follow'
    }).then(response => {
        if (response.status === 200) {
            response.text().then(body => {
                if (replace) {
                    $(element).replaceWith(body);

                } else {
                    $(element).append(body);
                }

            }).catch(err => {
                return Promise.reject(err);
            });

        } else {
            return Promise.reject(response.status);
        }

    }).catch(err => {
        console.error('failed to load', resourceUrl, err);
        return Promise.reject(err);
    });
};

/**
 * Use scrollToError binding is preferred.
 * @param complete a callback that gets executed, when the scrolling completes
 */
export const scrollToError = function (complete: () => void) {
    const $failedElement = $('.validationMessage').first();
    if ($failedElement.length > 0) {
        $failedElement.each(function () {
            $('html').animate({
                scrollTop: $failedElement.offset().top - 200
            }, 'fast', 'swing', function () {
                complete();
            });
        });

    } else {
        complete();
    }
};

export interface LanguagePath {
    url: string;
    language: string;
    languagePath: string;
    path: string;
}

export const languagePath = function (url): LanguagePath {
    const languageMatches = /^.*(?<path>(?<languagePath>\/(?<language>de|en))(?:|\/.*))$/.exec(url);

    if (languageMatches !== null) {
        return {
            url,
            language: languageMatches.groups.language,
            languagePath: languageMatches.groups.languagePath,
            path: languageMatches.groups.path
        };

    } else {
        return null;
    }
};