import {WidgetDto} from "../../../api/generated";
import * as ko from "knockout";
import * as common from "../_common";
import {autobind} from "knockout-decorators";
import "../widget-container/widget-container";
import * as modal from "../../../components/elements/modal/modal";
import i18nextko from "../../../bindings/i18nko";
import {components, Observable} from "knockout";
import Config = components.Config;


/**
 * Display parameters for widget.
 */
interface WidgetComponentPageViewModelParams extends common.WidgetComponentCompositionContext {
    save: Function;
}


/**
 * A page is the root entry point for showing widgets. Widget content contains
 * the layout to be used. Editing can be turned on an off for a page using its
 * editor. The widget is passed on to widget-container, was a page is implicitly
 * such an element.
 */
class WidgetComponentPageViewModel extends common.WidgetComponentModel<common.WidgetContentPage> {

    public editing: Observable<boolean>;
    public save: Function;

    constructor(params: WidgetComponentPageViewModelParams) {
        super(params.widget);
        this.save = params.save;
        this.editing = params.editing || ko.observable(false);
    }

    @autobind
    public edit() {
        return modal.createModal(
            {
                headerLabel: "",
                closeLabel: i18nextko.t('widget.edit.modal.close.label'),
                confirmLabel: null,
                modalBodyComponent: "widget-page-edit",
                params: {
                    widget: this.widget,
                    editing: this.editing
                }
            }
        ).catch(() => Promise.resolve());
    }

    @autobind
    public toggleEdit() {
        this.editing(!this.editing());
    }
}

ko.components.register("widget-page", <Config>{
    viewModel: (params: WidgetComponentPageViewModelParams) => new WidgetComponentPageViewModel(params),
    template: <string>require('./widget-page.html')
});


/**
 * Editor parameters for widget.
 */
interface WidgetPageEditViewModelParams extends common.WidgetComponentEditContext {
    params: {
        widget: WidgetDto;
        editing: Observable<boolean>;
    };
}

/**
 * The page editor component allows to start and stop the edit mode for the page and
 * all child widgets. This will acutally flip the "editing" observable which is
 * passed down through all widget components. It is the widgets responsibility to
 * actually hide the elements not required for editing if the editor is not active.
 */
class WidgetPageEditViewModel extends common.WidgetComponentEditModel<common.WidgetContentPage> {

    public editing: Observable<boolean>;

    constructor(ctx: WidgetPageEditViewModelParams) {
        super(ctx);
        this.editing = ctx.params.editing;

        ctx.callback.getResolveData = () => {
            return Promise.resolve();
        }
    }

    @autobind
    public startEdit() {
        this.editing(true);
    }

    @autobind
    public endEdit() {
        this.editing(false);
    }

}

ko.components.register("widget-page-edit", <Config>{
    viewModel: (params: WidgetPageEditViewModelParams) => new WidgetPageEditViewModel(params),
    template: <string>require('./widget-page-edit.html')
});
