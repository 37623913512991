import * as ko from "knockout";
import {components, Computed, Observable} from "knockout";
import * as common from "../_common";
import {observable} from "knockout-decorators";
import {setInterval} from "timers";
import "../../../components/elements/countdown/countdown";
import moment = require("moment");
import Config = components.Config;
import ComponentInfo = components.ComponentInfo;


/**
 * Display parameters for widget.
 */
interface WidgetCountdownViewModelParams extends common.WidgetComponentCompositionContext {

}

/**
 * Shows a countdown. Only a stub at the moment. This is the view model for showing
 * the countdown.
 */
class WidgetCountdownViewModel extends common.WidgetComponentCompositionModel<common.WidgetContentCountdown> {

    public now: Observable<Date>;
    public daysTotal: Computed<number>;
    public daysLeft: Computed<number>;
    public hoursLeft: Computed<number>;
    public minutesLeft: Computed<number>;
    public hasContent: Computed<boolean>;

    constructor(ctx: WidgetCountdownViewModelParams) {
        super(ctx);

        if (typeof this.widgetContent.start === 'undefined') {
            observable({deep: true, expose: true})(this.widgetContent, "start");
            (<common.WidgetContentCountdown>ctx.widget.content).start = null;
        }
        if (typeof this.widgetContent.end === 'undefined') {
            observable({deep: true, expose: true})(this.widgetContent, "end");
            (<common.WidgetContentCountdown>ctx.widget.content).end = null;
        }
        if (typeof this.widgetContent.headline === 'undefined') {
            observable({deep: true, expose: true})(this.widgetContent, "headline");
            (<common.WidgetContentCountdown>ctx.widget.content).headline = null;
        }
        if (typeof this.widgetContent.description === 'undefined') {
            observable({deep: true, expose: true})(this.widgetContent, "description");
            (<common.WidgetContentCountdown>ctx.widget.content).description = null;
        }
        if (typeof this.widgetContent.linkUrl === 'undefined') {
            observable({deep: true, expose: true})(this.widgetContent, "linkUrl");
            (<common.WidgetContentCountdown>ctx.widget.content).linkUrl = null;
        }
        if (typeof this.widgetContent.linkLabel === 'undefined') {
            observable({deep: true, expose: true})(this.widgetContent, "linkLabel");
            (<common.WidgetContentCountdown>ctx.widget.content).linkLabel = null;
        }

        this.hasContent = ko.pureComputed(() =>
            this.widgetContent.headline != null || this.widgetContent.description != null
            || (this.widgetContent.linkUrl != null && this.widgetContent.linkLabel != null)
        );

        this.now = ko.observable(new Date());
        this.daysTotal = ko.pureComputed(() =>
            moment(this.widgetContent.end).endOf('day')
                .diff(moment(this.widgetContent.start).startOf('day'), 'days')
        );
        this.daysLeft = ko.pureComputed(() =>
            moment(this.widgetContent.end).endOf('day').diff(this.now(), 'days')
        );
        this.hoursLeft = ko.pureComputed(() =>
            moment().endOf('day').diff(this.now(), 'hours')
        );
        this.minutesLeft = ko.pureComputed(() =>
            moment().endOf('hour').diff(this.now(), 'minutes')
        );
        setInterval(() => {
                this.now(new Date())
            }, 60000
        );

    }

    public editComponent() {
        return "widget-countdown-edit";
    }

}

let componentWidgetRichtext: Config = {
    viewModel: (params: WidgetCountdownViewModelParams, componentInfo?: ComponentInfo) => new WidgetCountdownViewModel(params),
    template: <string>require('./widget-countdown.html')
};

ko.components.register("widget-countdown", componentWidgetRichtext);


/**
 * Editor parameters for widget.
 */
interface WidgetCountdownEditViewModelParams extends common.WidgetComponentEditContext {
}

/**
 * Shows a countdown. Only a stub at the moment. This is the view model for editing
 * the countdown.
 */
class WidgetCountdownEditViewModel extends common.WidgetComponentEditModel<common.WidgetContentCountdown> {

    @observable({deep: false, expose: true})
    public start: Date;

    @observable({deep: false, expose: true})
    public end: Date;

    @observable({expose: true, deep: true})
    public headline: string;

    @observable({expose: true, deep: true})
    public description: string;

    @observable({expose: true, deep: true})
    public linkUrl: string;

    @observable({expose: true, deep: true})
    public linkLabel: string;

    constructor(ctx: WidgetCountdownEditViewModelParams) {
        super(ctx);
        this.start = this.widgetContent.start || new Date();
        this.end = this.widgetContent.end || moment().add(1, "month").toDate();
        this.headline = this.widgetContent.headline;
        this.description = this.widgetContent.description;
        this.linkUrl = this.widgetContent.linkUrl;
        this.linkLabel = this.widgetContent.linkLabel;

        ctx.callback.getResolveData = () => {
            this.widgetContent.start = this.start;
            this.widgetContent.end = this.end;
            this.widgetContent.headline = this.headline;
            this.widgetContent.description = this.description;
            this.widgetContent.linkUrl = this.linkUrl;
            this.widgetContent.linkLabel = this.linkLabel;
            return Promise.resolve();
        }
    }
}


let componentWidgetRichtextEdit: Config = {
    viewModel: (params: WidgetCountdownEditViewModelParams, componentInfo?: ComponentInfo) =>
        new WidgetCountdownEditViewModel(params),
    template: <string>require('./widget-countdown-edit.html')
};

ko.components.register("widget-countdown-edit", componentWidgetRichtextEdit);
