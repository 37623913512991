/* From https://github.com/adrotec/knockout-date-bindings/blob/master/knockout-date-bindings.js */

import * as ko from "knockout"
import * as moment from 'moment'

function getDateFormat(type: string, defaultDateFormat: string) {
    let dateFormat = defaultDateFormat;
    if (type == 'date') {
        dateFormat = 'YYYY-MM-DD';
    } else if (type == 'datetime-local' || type == 'datetime') {
        dateFormat = 'YYYY-MM-DDTHH:mm';
    } else if (type == 'month') {
        dateFormat = 'YYYY-MM';
    } else if (type == 'time') {
        dateFormat = 'hh:mm';
    } else if (type == 'week') {
        dateFormat = 'GGGG-[W]WW';
    }
    return dateFormat;
}

// noinspection JSUnusedLocalSymbols
ko.bindingHandlers.date = {
    init: function (element: HTMLInputElement, valueAccessor, allBindingsAccessor) {
        element.onchange = function () {
            let value = valueAccessor();
            let dateFormat = allBindingsAccessor().dateFormat
                ? ko.utils.unwrapObservable(allBindingsAccessor().dateFormat) : 'L';
            let d;
            if (element.tagName == 'INPUT') {
                const type = element.type;
                dateFormat = getDateFormat(type, dateFormat);
                d = moment(element.value, dateFormat);
                if (type == 'date' || type == 'month' || type == 'week') {
                    const newD = moment();
                    // noinspection TypeScriptValidateJSTypes
                    d.hour(newD.hour());
                    // noinspection TypeScriptValidateJSTypes
                    d.minute(newD.minute());
                    // noinspection TypeScriptValidateJSTypes
                    d.second(newD.second());
                    if (type == 'month' || type == 'week') {
                        d.date(newD.date());
                    }
                }
            } else {
                d = moment(element.textContent, dateFormat);
            }
            if (d) {
                if (typeof value === "function") {
                    value(d.toDate());
                } else { // noinspection SuspiciousInstanceOfGuard
                    if (value instanceof Date) {
                        value.setTime(d.toDate().getTime());
                    } else {
                        // noinspection JSUnusedAssignment
                        value = d.toDate();
                    }
                }
            } else {
                if (typeof value === "function") {
                    value(null);
                } else { // noinspection SuspiciousInstanceOfGuard
                    if (value instanceof Date) {
                        value.setTime(0);
                    } else {
                        value = null;
                    }
                }
            }
        };
    },
    update: function (element: HTMLInputElement, valueAccessor, allBindingsAccessor, viewModel) {
        const value = valueAccessor();
        const valueUnwrapped = ko.utils.unwrapObservable(value);
        if (valueUnwrapped) {

            function updateTimeValue() {
                let dateFormat = allBindingsAccessor().dateFormat
                    ? ko.utils.unwrapObservable(allBindingsAccessor().dateFormat) : 'L';
                if (element.tagName == 'INPUT') {
                    if (valueUnwrapped instanceof Date && valueUnwrapped.getTime() === 0) {
                        element.value = '';
                    } else {
                        dateFormat = getDateFormat(element.type, dateFormat);
                        element.value = moment(valueUnwrapped).format(dateFormat);
                    }
                } else {
                    element.textContent = moment(valueUnwrapped).format(dateFormat);
                }
            }

            if (valueUnwrapped instanceof Date) {
                const setTimeOld = valueUnwrapped.setTime;
                valueUnwrapped.setTime = function (time: number) {
                    setTimeOld.apply(valueUnwrapped, arguments);
                    updateTimeValue();
                    return time;
                }
            }
            updateTimeValue();
        }
    }
};