import globalAxios from "axios";
import globalState from "../global-state";
import {Configuration, ConfigurationParameters, ContentApi} from "./generated";
import {config} from "../utils/clientConfigWrapper";

const axiosOptions: ConfigurationParameters = {
    baseOptions: {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8', // fixes response status 415
            'Accept': 'application/json' // fixes response status 406
        }
    }
};

globalAxios.interceptors.request.use(function (config) {
    // Do something before request is sent
    if (globalState.accessToken()) {
        config.headers['authorization'] = `Bearer ${globalState.accessToken()}`;
    }
    return config;
});

export const contentApi = new ContentApi(new Configuration(axiosOptions), config.endpoint);

