class NotFoundPageViewModel {

}

// noinspection JSUnusedLocalSymbols
function load(ctx) {
    return Promise.resolve();
}

export default <KnockoutLazyComponentDefinition>{
    viewModel: NotFoundPageViewModel,
    loader: load,
    template: require('./404.html'),
    name: '404-page'
};
