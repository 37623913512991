import globalState from './global-state';
import {postbox} from './components/util/postbox';
import * as ko from 'knockout';
import 'bootstrap';
import 'knockstrap';
import 'knockout.validation'
import "./bindings/knockout-date-bindings";
import "./bindings/knockout-select2";
import "./bindings/knockout-date-picker";
import "./bindings/knockout-foreach-groups";
import "./bindings/knockout-linkify";
import "./bindings/knockout-checked-inverse";
import "./bindings/enterkey"
import "./bindings/path"
import 'knockout-file-bindings';
import "./bindings/knockout.tab";
import "./bindings/knockout-gallery";
import "./bindings/knockout-carousel";
import * as moment from "moment";
import {Context, Router} from "@profiscience/knockout-contrib-router";
import scrollToError from './bindings/scrollToError';
import enterkey from "./bindings/enterkey";
import blur from "./bindings/blur";
import i18nextko from './bindings/i18nko'
import {computed} from "knockout-decorators";
import jwtDecode from "jwt-decode";
import {Auth0Client} from "@3kraft/auth0-spa-js";
import {auth0ClientOptions, auth0GetTokenSilentlyOptions, auth0RedirectLoginOptions} from "./utils/auth0Options";
import {config} from "./utils/clientConfigWrapper";
import * as Cookies from 'js-cookie';
import * as utils from "./utils/utils";

export const auth0 = new Auth0Client(auth0ClientOptions);

class PageViewModel {

}

/**
 * The root view model of the application.
 */
class AppViewModel {

    /**
     * Shows/Hides the loading indicator
     */
    public loading = globalState.loading;

    /**
     * Triggers scrollToError binding.
     */
    public hasErrors: ko.PureComputed<boolean>;

    /**
     * Language setting of the client.
     */
    public language: ko.Observable<string> = ko.observable("de");

    constructor() {
        /**
         * Scroll to error if postbox has errors or globalState.hasErrors (to trigger the scrolling manually)
         */
        this.hasErrors = ko.pureComputed(() => {
            return postbox.errors().length > 0 || globalState.hasErrors();
        }, this).extend({rateLimit: 500});

    }

    @computed
    public get isAdmin(): boolean {
        return globalState.decodedToken() && globalState.decodedToken().permissions.indexOf('access:admin') > -1;
    }

    @computed
    public get isLoggedIn(): boolean {
        return !!globalState.decodedToken();
    }

    /**
     * Translates a key.
     *
     * @param key - see https://www.i18next.com/overview/api#t
     * @param options - see https://www.i18next.com/translation-function/interpolation
     */
    public i18n(key: String, options?: object) {
        return i18nextko.t(key, options);
    }

    // noinspection JSUnusedGlobalSymbols
    /**
     * - Switches i18n language.
     * - Sets a language cookie to save the clients language.
     * - Routes to language page.
     *
     * @param selectedLanguage
     */
    public switchLanguage(selectedLanguage) {
        this.language(selectedLanguage);
        globalState.language(selectedLanguage);
        Cookies.set('language', selectedLanguage, {
            expires: 365,
            secure: true,
            sameSite: 'strict'
        });
        i18nextko.setLanguage(selectedLanguage);

        const languagePath = utils.languagePath(window.location.href);
        if (languagePath !== null) {
            const route = languagePath.path.replace(languagePath.languagePath, `/${selectedLanguage}`);
            Router.update(route, {push: true, force: false})
                .then(() => console.debug('app: successfully updated route', route))
                .catch(err => console.error('app: failed to update route', err));
        }
    }

    /**
     * Language chosser images.
     */
    @computed
    public get selectedlanguageImage() {
        return `images/${this.language()}.png`;
    }

    // noinspection JSUnusedGlobalSymbols
    /**
     * Calculates locale specific route path. Used be reactivePath binding in menu.
     * @param path
     */
    public localizedPath(path: string) {
        return ko.pureComputed(() => {
            return `//${this.language()}/${path}`;
        });
    }

    // noinspection JSUnusedGlobalSymbols
    /**
     * The configured site: Tulln or Klosterneuburg
     */
    public get site(): string {
        return config.site;
    }
}

/**
 * The application controller.
 */
export class App {

    /**
     * The root view model instance.
     */
    public viewModel: AppViewModel;

    /**
     * Initializes the root view model.
     */
    constructor() {
        this.viewModel = new AppViewModel();
    }

    // noinspection JSUnusedLocalSymbols
    private init(): Promise<void> {
        return auth0.handleRedirectCallback().then(
            redirectLoginResult => {
                console.debug("app: successfully logged in");

                // restore returnTo appState, if set
                history.replaceState({}, '',
                    redirectLoginResult.appState && redirectLoginResult.appState.returnTo ?
                        redirectLoginResult.appState.returnTo : window.location.href
                );
            })
            .catch(() => {
                console.debug("app: not logged in");
            })
            .finally(() => {
                return auth0
                    .getTokenSilently(auth0GetTokenSilentlyOptions)
                    .then(token => {
                        globalState.accessToken(token);
                        globalState.decodedToken(jwtDecode(token));

                        console.debug("app: successfully got token", globalState.accessToken(), globalState.decodedToken());
                    })
                    .catch(() => console.debug("app: not logged in"))
                    .finally(() => this._init());
            });
    }

    /**
     * Initializes the app:
     * - registers custom events: headerInitialized
     * - sets up the ko-component-router for navigation between pages
     * - registers loading and scrollTop router middleware
     * - registers additional binding handlers: scrollToError
     * - registers custom components: text-input
     * - loads translations: i18next
     * - initializes ko validation
     * - applies bindings to root view model
     */
    private _init(): Promise<void> {
        const language = utils.languagePath(window.location.href)?.language || 'de';
        this.viewModel.language(language);
        globalState.language(language);
        moment.locale(language);

        console.debug('app: router base', '/' + config.publicPath || '');
        //setup router
        Router.setConfig({
            base: '/' + config.publicPath || ''
        });

        Router.useRoutes({
            '/admin': [App.loggedIn, App.loadPage('home')],
            '/de': {
                '/': [App.loadPage('home')],
                '/camping': [App.loadPage('camping')],
                '/mobile-homes': [App.loadPage('mobile-homes')],
                '/leisure': [App.loadPage('leisure')],
                '/permanent-stay': [App.loadPage('permanent-stay')],
                '/prices': [App.loadPage('prices')],
                '/imprint': [App.loadPage('imprint')],
                '/privacy': [App.loadPage('privacy')]
            },
            '/en': {
                '/': [App.loadComponent('home/home.ts')],
                '/camping': [App.loadPage('camping')],
                '/mobile-homes': [App.loadPage('mobile-homes')],
                '/leisure': [App.loadPage('leisure')],
                '/permanent-stay': [App.loadPage('permanent-stay')],
                '/prices': [App.loadPage('prices')],
                '/imprint': [App.loadPage('imprint')],
                '/privacy': [App.loadPage('privacy')]
            }
        });

        Router.use(App.loadingMiddleware);
        Router.use(App.scrollTopMiddlware);
        Router.use(App.anchorLinksMiddlware);
        Router.use(App.hideResponsiveMenuMiddleware)

        // register bindings
        ko.bindingHandlers.scrollToError = scrollToError;
        ko.bindingHandlers.enterkey = enterkey;
        ko.bindingHandlers.blur = blur;

        // register components
        ko.components.register('notifications',
            require('./components/notifications/notifications').default);
        ko.components.register('menu',
            require('./components/elements/menu/menu').default);
        ko.components.register('footer',
            require('./components/elements/footer/footer').default);
        ko.components.register('booking',
            require('./components/elements/booking/booking').default);

        // localizations and validations
        return this.loadTranslations()
            .then(() => {
                // initialize validation
                ko.validation.init({
                    insertMessages: false,
                    grouping: {
                        deep: true,
                        live: true,
                        observable: true
                    },
                    messagesOnModified: true
                }, true);
                ko.validation.localize(i18nextko.t('validation', {returnObjects: true})());

                ko.applyBindings(this.viewModel, document.documentElement);
            });
    }

    /**
     * Loads and registers (if not done before) a component. Used be the router to load pages.
     * @param componentName the component name
     * @returns Promise<void> a Promise
     */
    static loadComponent(componentName: string) {
        return (ctx: Context) => {
            console.debug("app: loading component " + componentName);

            return import("./pages/" + componentName)
                .then(loadedComponent => {
                    let loadedComponentDefinition =
                        <KnockoutLazyComponentDefinition>loadedComponent.default;
                    console.debug("app: loaded component " + componentName);

                    if (!ko.components.isRegistered(loadedComponentDefinition.name)) {
                        ko.components.register(loadedComponentDefinition.name, loadedComponentDefinition);
                        console.debug("app: registered component " + loadedComponentDefinition.name);
                    }

                    let loaderPromise;
                    if (loadedComponentDefinition.loader) {
                        loaderPromise = loadedComponentDefinition.loader(ctx);

                    } else {
                        loaderPromise = Promise.resolve();
                    }

                    return loaderPromise.then(() => {
                        console.debug("app: setting ctx.router.component to component " +
                            loadedComponentDefinition.name);
                        ctx.route.component = loadedComponentDefinition.name;

                        return Promise.resolve();
                    });
                })
                .catch(err => {
                    console.error("app: load failed", err);
                    if (err.status && (err.status == 404 || err.status == 500)) {
                        postbox.addError(`load.status.${err.status}`, {error: err}, err);
                    } else {
                        postbox.addError("load.failed", {error: err}, err);
                    }
                    globalState.loading(false);

                    return Promise.reject(err);
                });
        };
    }

    static loadPage(pageName: string) {
        return (ctx: Context) => {
            const language = ctx.$parent.route.path.substring(1);
            const name = `${pageName}-${config.site}-${language}`;
            console.debug('app: loading site page', name);

            return import(`./pages/${pageName}/${pageName}-${config.site}-${language}.html`)
                .catch(() => {
                    console.debug('app: fallback to generic page', `${pageName}-${language}`);
                    return import(`./pages/${pageName}/${pageName}-${language}.html`);
                })
                .then(loadedTemplate => {
                    const loadedComponentDefinition: KnockoutLazyComponentDefinition = {
                        name: name,
                        viewModel: PageViewModel,
                        template: loadedTemplate,
                        loader: () => Promise.resolve()
                    };

                    if (!ko.components.isRegistered(name)) {
                        ko.components.register(name, loadedComponentDefinition);
                        console.debug("app: registered page " + name);
                    }

                    return loadedComponentDefinition.loader ?
                        loadedComponentDefinition.loader(ctx) : Promise.resolve();

                })
                .then(() => {
                    ctx.route.component = name;

                    return Promise.resolve();

                })
                .catch(err => {
                    console.error("app: load failed", err);
                    if (err.status && (err.status == 404 || err.status == 500)) {
                        postbox.addError(`error.load.status.${err.status}`, {error: err}, err);
                    } else {
                        postbox.addError("error.load.failed", {error: err}, err);
                    }

                    return Promise.reject(err);
                })
                .finally(() => globalState.loading(false));
        };
    }

    /**
     * Page loading indicator router middleware.
     */
    static loadingMiddleware() {
        return {
            beforeRender() {
                globalState.loading(true);
            },
            afterRender() {
                globalState.loading(false);
            }
        };
    }

    /**
     * Scroll to top after page load middleware
     */
    static scrollTopMiddlware() {
        return {
            beforeRender() {
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'auto'
                });
            }
        };
    }

    /**
     * Scroll to anchor links after page load middleware.
     */
    static anchorLinksMiddlware() {
        return {
            afterRender() {
                const hash = (location.pathname + location.hash)
                    .replace(/#!/, '')
                    .replace(/^[^#]+#?/, '');
                if (hash) {
                    const anchor = document.getElementById(hash);
                    if (anchor !== null) {
                        const y = anchor.offsetTop;
                        window.scrollTo(0, y)
                    } else {
                        console.warn('app: anchorLinksMiddlware:',
                            `Navigated to page with #${hash}, but no element with id ${hash} found.`);
                    }
                }
            }
        };
    }

    /**
     * Hide the responsive menu if it's opened.
     */
    static hideResponsiveMenuMiddleware() {
        return {
            afterRender() {
                const toggle = document.getElementById("navbar-toggler");
                const attrExpanded = toggle ? toggle.getAttribute('aria-expanded') : false;
                if (attrExpanded && attrExpanded === 'true') {
                    toggle.click();
                }
            }
        }
    }

    loadTranslations() {
        i18nextko
            .init({
                debug: false,
                compatibilityAPI: 'v3',
                lng: this.viewModel.language() || 'de',
                fallbackLng: 'en',
                resources: {
                    en: require(`../l10n/en-${config.site}.json`),
                    de: require(`../l10n/de-${config.site}.json`)
                }
            });

        return Promise.resolve();
    }

    static loggedIn() {
        return {
            beforeRender() {
                if (!!globalState.decodedToken()) {
                    return Promise.resolve();
                }
                return Promise.reject().finally(() => auth0.loginWithRedirect(auth0RedirectLoginOptions));
            }
        }
    }
}
