import * as ko from "knockout";
import {components} from "knockout";
import * as common from "../_common";
import {observable} from "knockout-decorators";
import "../../../bindings/knockout-quill";
import Config = components.Config;
import ComponentInfo = components.ComponentInfo;


/**
 * Display parameters for widget.
 */
interface WidgetRichtextViewModelParams extends common.WidgetComponentCompositionContext {
}

/**
 * A rich text widget shows HTML stored in the attribute 'body' of the widgets content.
 * It uses a rich text editor for editing.
 */
class WidgetRichtextViewModel extends common.WidgetComponentCompositionModel<common.WidgetContentRichtext> {

    constructor(ctx: WidgetRichtextViewModelParams) {
        super(ctx);
        if (typeof this.widgetContent.body === 'undefined') {
            observable({deep: true, expose: true})(this.widgetContent, "body");
            (<common.WidgetContentRichtext>ctx.widget.content).body = null;
        }
        if (typeof this.widgetContent.collapsible === 'undefined') {
            observable({deep: false, expose: true})(this.widgetContent, "collapsible");
            this.widgetContent.collapsible = false;
        }
    }


    public editComponent() {
        return "widget-richtext-edit";
    }

}


/**
 * Editor parameters for widget.
 */
interface WidgetRichtextEditViewModelParams extends common.WidgetComponentEditContext {
}

/**
 * Shows a rich text editor. Only when the changes are applied, the modified markup is
 * stored into the widget.
 */
class WidgetRichtextEditViewModel extends common.WidgetComponentEditModel<common.WidgetContentRichtext> {

    @observable({expose: true, deep: true})
    public body: string;

    constructor(ctx: WidgetRichtextEditViewModelParams) {
        super(ctx);
        this.body = this.widgetContent.body;
        ctx.callback.getResolveData = () => {
            this.widgetContent.body = this.body;
            return Promise.resolve();
        }
    }
}

let componentWidgetRichtext: Config = {
    viewModel: (params: WidgetRichtextViewModelParams, componentInfo?: ComponentInfo) =>
        new WidgetRichtextViewModel(params),
    template: <string>require('./widget-richtext.html')
};

ko.components.register("widget-richtext", componentWidgetRichtext);


let componentWidgetRichtextEdit: Config = {
    viewModel: (params: WidgetRichtextEditViewModelParams, componentInfo?: ComponentInfo) =>
        new WidgetRichtextEditViewModel(params),
    template: <string>require('./widget-richtext-edit.html')
};

ko.components.register("widget-richtext-edit", componentWidgetRichtextEdit);
