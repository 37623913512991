import {LogoutOptions, RedirectLoginOptions, Auth0ClientOptions, GetTokenSilentlyOptions} from "@3kraft/auth0-spa-js";
import {config} from "./clientConfigWrapper";

export const auth0ClientOptions: Auth0ClientOptions = {
    domain: config.auth0Options.domain,
    issuer: config.auth0Options.issuer,
    clientId: config.auth0Options.client_id,
    cacheLocation: 'localstorage',
    useRefreshTokens: true,
    tokenEndpoint: config.auth0Options.provider == 'AzureAD' ? '/token' : '/oauth/token'
};

export const auth0GetTokenSilentlyOptions: GetTokenSilentlyOptions = {
    authorizationParams: {
        redirect_uri: config.auth0Options.redirect_uri,
        audience: config.auth0Options.audience,
        scope: config.auth0Options.scope
    }
};

export const auth0RedirectLoginOptions: RedirectLoginOptions = {
    authorizationParams: {
        redirect_uri: config.auth0Options.redirect_uri,
        audience: config.auth0Options.audience,
        scope: config.auth0Options.scope,
        ui_locales: config.auth0Options.ui_locales
    }
};

export const auth0LogoutOptions: LogoutOptions = {
    logoutEndpoint: config.auth0Options.provider == 'AzureAD' ? '/logout' : '/v2/logout',
    openUrl: url => Promise.resolve(window.location.replace(url)),
    logoutParams: {
        returnTo: config.auth0Options.redirect_uri
    }
}
