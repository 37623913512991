import * as ko from "knockout"
import {BindingHandler} from "knockout"
import * as $ from "jquery";
// Unconditionally import bootstrap-datepicker
import "bootstrap-datepicker"
import "bootstrap-datepicker/js/locales/bootstrap-datepicker.de"
import "bootstrap-datepicker/dist/css/bootstrap-datepicker3.css"
import moment = require("moment");

declare global {
    interface DatepickerBindingHandler extends BindingHandler {
        defaults: any
    }
}

// https://www.codeproject.com/Tips/780329/Bootstrap-datepicker-and-Knockout-model-binding
ko.bindingHandlers.datepicker = <DatepickerBindingHandler>{
    defaults: {
        autoclose: true,
        format: 'dd.mm.yyyy',
        sourceFormat: null, // describes the format if the observable is a string or null if it is a date
        endOf: null,         // 'day' | 'month' - sets the selected observable date to the end of the day | month.
        language: 'de',
        weekStart: 1
    },
    init: function (element, valueAccessor, allBindingsAccessor) {
        const options = $.extend(true, {}, (<DatepickerBindingHandler>ko.bindingHandlers.datepicker).defaults,
            allBindingsAccessor().datepickerOptions);
        $(element).datepicker(options).on("changeDate, hide", function (ev) {
            // observable value might be written at the end of the day or end of the month
            const value = (options.endOf) ? moment(ev.date).endOf(options.endOf).toDate() : ev.date;
            // observable value is written as string or date - depending on the sourceFormat option.
            const newDate: string | Date = (options.sourceFormat) ? moment(value).format(options.sourceFormat) : value;
            console.debug("date-picker-binding: changed date to " + newDate, typeof newDate);
            const observable = valueAccessor();
            observable(newDate);
        }).on("clearDate", function (ev) {
            console.debug("date-picker-binding: Datepicker cleared ");
            const observable = valueAccessor();
            observable(null);

        });
        const value = ko.utils.unwrapObservable(valueAccessor());
        // observable value might be a string and has to be converted to a date
        const initialDate: Date = (options.sourceFormat) ? moment(value, options.sourceFormat).toDate() : value;
        $(element).datepicker("setDate", initialDate);

        ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
            $(element).datepicker("destroy");
        });
    },
    update: function (element, valueAccessor, allBindingsAccessor) {
        const options = $.extend(true, {}, (<DatepickerBindingHandler>ko.bindingHandlers.datepicker).defaults,
            allBindingsAccessor().datepickerOptions);
        const value = ko.utils.unwrapObservable(valueAccessor());
        // observable value might be a string and has to be converted to a date
        const newDate: Date = (options.sourceFormat) ? moment(value, options.sourceFormat).toDate() : value;
        $(element).datepicker("setDate", newDate);
    }
};
