import * as ko from 'knockout';
import {AllBindings} from 'knockout';
// Unconditionally import blueimp-gallery
import * as BlueimpGallery from "blueimp-gallery";
import "blueimp-gallery/css/blueimp-gallery.css";

const knockoutMapping = require('knockout-mapping');

// see https://github.com/blueimp/Gallery#lightbox-setup
ko.bindingHandlers.gallery = {
    init: function (element, valueAccessor, allBindingsAccessor: AllBindings) {
        const options = knockoutMapping.toJS( ko.utils.unwrapObservable(valueAccessor()) );
        const itemsSelector = options.itemsSelector ? options.itemsSelector : 'a';
        const $items = $(element).find(itemsSelector);
        $items.each(function(index) {
            const item = this;
            $(this).on("click", function(event) {
               event.preventDefault();
               BlueimpGallery($items.get(), { index: this, event: event })
            });
        });
    },
    update: function (element, valueAccessor) {
        // for now gallery items are not observables
    }
};

