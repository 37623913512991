import * as ko from 'knockout';
import {resolveHref, Router} from '@profiscience/knockout-contrib-router';
import {getRouterForBindingContext, log, traversePath} from '@profiscience/knockout-contrib-router/dist/utils';
import {activePathBinding} from './active-path';

export var pathBinding = {
    init: function (el, valueAccessor, allBindings, viewModel, bindingCtx) {
        activePathBinding.init.apply(this, arguments);

        Router.initialized
            .then(() => {
                const router = getRouterForBindingContext(bindingCtx);
                const route = ko.pureComputed(() => traversePath(router, ko.unwrap(valueAccessor())));

                ko.applyBindingsToNode(el, {
                    attr: {
                        href: ko.pureComputed(() => resolveHref(route()))
                    }
                }, bindingCtx);
            })
            .catch(err => log.error('Error initializing path binding', err));
    }
};

ko.bindingHandlers.reactivePath = pathBinding;
