import {autobind, computed, observable} from "knockout-decorators"
import {WidgetDto, WidgetDtoTypeEnum} from "../../api/generated";
import * as modal from "../../components/elements/modal/modal"
import * as ko from "knockout";
import * as uuid from 'uuid';
import i18nextko from "../../bindings/i18nko";
import _isEmpty from "lodash-es/isEmpty";
import _some from "lodash-es/some";
import {Observable, ObservableArray} from "knockout";

/**
 * Some common definitions for the widgets.
 */

/**
 * This interface describes the widgets "content". Each type has a definition
 * and this definition only exists client side. The server does not have
 * visibility into the content.
 */
export interface WidgetContent {
    title: string;
    collapsible: boolean;
}


/**
 * Widget content definition for a widget container.
 */
export interface WidgetContentContainer extends WidgetContent {
    layout: string;
}

/**
 * Widget content definition for a page. A page is always a widget
 * container, thus it extends the interface of this component.
 */
export interface WidgetContentPage extends WidgetContentContainer {
}

/**
 * Rich text stores the markup to show in the attribute "body".
 */
export interface WidgetContentRichtext extends WidgetContent {
    body: string;
}

/**
 * Cite content.
 */
export interface WidgetContentCite extends WidgetContent {
    cite: string;
    name: string;
    imageRef: string;
    mimetype: string
}

/**
 * Video content.
 */
export interface WidgetContentVideo extends WidgetContent {
    ref: string
    mimetype: string
}

/**
 * Image content.
 */
export interface WidgetContentImage extends WidgetContent {
    ref: string
    mimetype: string
}

/**
 * Gallery content.
 */
export interface WidgetContentGallery extends WidgetContent {
    items: WidgetContentImage[]
}

/**
 * Countdown content.
 */
export interface WidgetContentCountdown extends WidgetContent {
    start: Date;
    end: Date;
    headline: string;
    description: string;
    linkUrl: string;
    linkLabel: string;
}

/**
 * Component parameters for a widget.
 */
export interface WidgetComponentCompositionContext {
    widget: WidgetDto;
    dragable: boolean;
    editing: Observable<boolean>;

    removeMe(): void;

    setWidgetType(): void;
}

/**
 * Component parameters for a widget editor.
 */
export interface WidgetComponentEditContext extends modal.ModalBodyComponentContext {
    params: {
        widget: WidgetDto;
    };
}

export interface FileData {
    file: Observable<File>;
    dataURL?: Observable<string>;
    base64String?: Observable<string>;
}

export interface FilesData {
    file: Observable<File>;
    dataURL?: Observable<string>;

    fileArray?: ObservableArray<File>;
    base64StringArray?: ObservableArray<string>;
}

/**
 * Common superclass for all widget components. Exposes the widget content
 * as a typed attribute.
 */
export abstract class WidgetComponentModel<T extends WidgetContent> {

    public domId: string;

    @observable({deep: true, expose: true})
    public widget: WidgetDto;

    constructor(widget: WidgetDto) {
        if (!widget.content) {
            widget.content = {};
        }
        this.widget = widget;
        //this.widgetContent = <T>widget.content;
        // stores a random id for use in the dom
        this.domId = uuid();
    }

    @computed
    public get widgetContent(): T {
        return <T>this.widget.content;
    }

    public i18n(key: String, options?: object) {
        return i18nextko.t(key, options);
    }

    /**
     * Checks if the widget content object has properties set.
     */
    @computed
    public get isContentSet() {
        console.debug('is empty', this.widgetContent, !_some(this.widgetContent, property => !_isEmpty(property)));
        return _some(this.widgetContent, property => !_isEmpty(property));
    }
}

/**
 * Common superclass for all widget editors.
 */
export abstract class WidgetComponentEditModel<T extends WidgetContent> extends WidgetComponentModel<T> {

    constructor(ctx: WidgetComponentEditContext) {
        super(ctx.params.widget);
    }
}

/**
 * Common superclass for all main widget components.
 * Extracts common attributes from parameters
 */
export abstract class WidgetComponentCompositionModel<T extends WidgetContent> extends WidgetComponentModel<T> {

    public removeMe: Function;
    public dragable: boolean;
    public editing = ko.observable(false);

    constructor(ctx: WidgetComponentCompositionContext) {
        super(ctx.widget);
        this.removeMe = ctx.removeMe;
        this.dragable = ctx.dragable;
        this.editing = ctx.editing;
    }

    @autobind
    public edit() {
        if (this.editComponent()) {
            return modal.createModal(
                {
                    headerLabel: "",
                    closeLabel: i18nextko.i18n.t("widget.modal.button.close"),
                    confirmLabel: i18nextko.i18n.t("widget.modal.button.confirm"),
                    modalBodyComponent: this.editComponent(),
                    params: {
                        widget: this.widget
                    }
                }
            ).catch(
                () => Promise.resolve()
            );
        } else {
            return Promise.resolve();
        }
    }

    /**
     * The name of the component for editing.
     */
    public abstract editComponent(): string
}

/**
 * A type registry. This type registry contains a mapping of widget type to
 * component name responsible for showing the widget.
 */
export const typeComponentRegistry: Map<WidgetDtoTypeEnum, string> = new Map();
typeComponentRegistry.set(WidgetDtoTypeEnum.Page, "widget-page");
typeComponentRegistry.set(WidgetDtoTypeEnum.Empty, "widget-empty");
typeComponentRegistry.set(WidgetDtoTypeEnum.WidgetContainer, "widget-container");
typeComponentRegistry.set(WidgetDtoTypeEnum.RichText, "widget-richtext");
typeComponentRegistry.set(WidgetDtoTypeEnum.Cite, "widget-cite");
typeComponentRegistry.set(WidgetDtoTypeEnum.Image, "widget-image");
typeComponentRegistry.set(WidgetDtoTypeEnum.Gallery, "widget-gallery");
typeComponentRegistry.set(WidgetDtoTypeEnum.Video, "widget-video");
typeComponentRegistry.set(WidgetDtoTypeEnum.Countdown, "widget-countdown");
