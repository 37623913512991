/* tslint:disable */
/* eslint-disable */
/**
 * oeamtc-camping-api
 * ÖAMTC Camping API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: jan.illetschko@3kraft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * used to upload attachments data in a post or put request without using multipart file uploads
 * @export
 * @interface UploadDto
 */
export interface UploadDto {
    /**
     * 
     * @type {string}
     * @memberof UploadDto
     */
    'filename'?: string;
    /**
     * the mime type of the uploaded file
     * @type {string}
     * @memberof UploadDto
     */
    'mimeType'?: string;
    /**
     * base64 encoded file data
     * @type {string}
     * @memberof UploadDto
     */
    'data'?: string;
}
/**
 * 
 * @export
 * @interface WidgetDto
 */
export interface WidgetDto {
    /**
     * 
     * @type {string}
     * @memberof WidgetDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof WidgetDto
     */
    'type'?: WidgetDtoTypeEnum;
    /**
     * 
     * @type {object}
     * @memberof WidgetDto
     */
    'content'?: object;
    /**
     * 
     * @type {Array<WidgetDto>}
     * @memberof WidgetDto
     */
    'children'?: Array<WidgetDto>;
}

export const WidgetDtoTypeEnum = {
    Page: 'PAGE',
    WidgetContainer: 'WIDGET_CONTAINER',
    RichText: 'RICH_TEXT',
    Video: 'VIDEO',
    Image: 'IMAGE',
    Gallery: 'GALLERY',
    Cite: 'CITE',
    Countdown: 'COUNTDOWN',
    Empty: 'EMPTY'
} as const;

export type WidgetDtoTypeEnum = typeof WidgetDtoTypeEnum[keyof typeof WidgetDtoTypeEnum];


/**
 * ContentApi - axios parameter creator
 * @export
 */
export const ContentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * deletes a widget
         * @param {string} widgetId an id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWidget: async (widgetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'widgetId' is not null or undefined
            assertParamExists('deleteWidget', 'widgetId', widgetId)
            const localVarPath = `/widgets/{widgetId}`
                .replace(`{${"widgetId"}}`, encodeURIComponent(String(widgetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", ["access:admin"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get assets from asset store
         * @param {string} assetId an id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssets: async (assetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetId' is not null or undefined
            assertParamExists('getAssets', 'assetId', assetId)
            const localVarPath = `/assets/{assetId}`
                .replace(`{${"assetId"}}`, encodeURIComponent(String(assetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * gets a widget
         * @param {string} widgetId an id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWidget: async (widgetId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'widgetId' is not null or undefined
            assertParamExists('getWidget', 'widgetId', widgetId)
            const localVarPath = `/widgets/{widgetId}`
                .replace(`{${"widgetId"}}`, encodeURIComponent(String(widgetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get widgets without a parent.
         * @param {number} [limit] limits the returned results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWidgets: async (limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/widgets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * performs a form upload
         * @param {File} file The file to upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFormUpload: async (file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('postFormUpload', 'file', file)
            const localVarPath = `/formUploads`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", ["access:admin"], configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * performs an upload
         * @param {Array<UploadDto>} body upload with meta data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUploads: async (body: Array<UploadDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('postUploads', 'body', body)
            const localVarPath = `/uploads`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", ["access:admin"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * creates a root widget.
         * @param {WidgetDto} body widget to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWidget: async (body: WidgetDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('postWidget', 'body', body)
            const localVarPath = `/widgets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", ["access:admin"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * updates a root widget.
         * @param {string} widgetId an id
         * @param {WidgetDto} body widget to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putWidget: async (widgetId: string, body: WidgetDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'widgetId' is not null or undefined
            assertParamExists('putWidget', 'widgetId', widgetId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('putWidget', 'body', body)
            const localVarPath = `/widgets/{widgetId}`
                .replace(`{${"widgetId"}}`, encodeURIComponent(String(widgetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication auth0 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "auth0", ["access:admin"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContentApi - functional programming interface
 * @export
 */
export const ContentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContentApiAxiosParamCreator(configuration)
    return {
        /**
         * deletes a widget
         * @param {string} widgetId an id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWidget(widgetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWidget(widgetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get assets from asset store
         * @param {string} assetId an id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssets(assetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssets(assetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * gets a widget
         * @param {string} widgetId an id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWidget(widgetId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WidgetDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWidget(widgetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get widgets without a parent.
         * @param {number} [limit] limits the returned results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWidgets(limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WidgetDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWidgets(limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * performs a form upload
         * @param {File} file The file to upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postFormUpload(file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postFormUpload(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * performs an upload
         * @param {Array<UploadDto>} body upload with meta data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUploads(body: Array<UploadDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUploads(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * creates a root widget.
         * @param {WidgetDto} body widget to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postWidget(body: WidgetDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WidgetDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postWidget(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * updates a root widget.
         * @param {string} widgetId an id
         * @param {WidgetDto} body widget to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putWidget(widgetId: string, body: WidgetDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WidgetDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putWidget(widgetId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContentApi - factory interface
 * @export
 */
export const ContentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContentApiFp(configuration)
    return {
        /**
         * deletes a widget
         * @param {string} widgetId an id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWidget(widgetId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteWidget(widgetId, options).then((request) => request(axios, basePath));
        },
        /**
         * get assets from asset store
         * @param {string} assetId an id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssets(assetId: string, options?: any): AxiosPromise<File> {
            return localVarFp.getAssets(assetId, options).then((request) => request(axios, basePath));
        },
        /**
         * gets a widget
         * @param {string} widgetId an id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWidget(widgetId: string, options?: any): AxiosPromise<WidgetDto> {
            return localVarFp.getWidget(widgetId, options).then((request) => request(axios, basePath));
        },
        /**
         * get widgets without a parent.
         * @param {number} [limit] limits the returned results
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWidgets(limit?: number, options?: any): AxiosPromise<Array<WidgetDto>> {
            return localVarFp.getWidgets(limit, options).then((request) => request(axios, basePath));
        },
        /**
         * performs a form upload
         * @param {File} file The file to upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFormUpload(file: File, options?: any): AxiosPromise<string> {
            return localVarFp.postFormUpload(file, options).then((request) => request(axios, basePath));
        },
        /**
         * performs an upload
         * @param {Array<UploadDto>} body upload with meta data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUploads(body: Array<UploadDto>, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.postUploads(body, options).then((request) => request(axios, basePath));
        },
        /**
         * creates a root widget.
         * @param {WidgetDto} body widget to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWidget(body: WidgetDto, options?: any): AxiosPromise<WidgetDto> {
            return localVarFp.postWidget(body, options).then((request) => request(axios, basePath));
        },
        /**
         * updates a root widget.
         * @param {string} widgetId an id
         * @param {WidgetDto} body widget to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putWidget(widgetId: string, body: WidgetDto, options?: any): AxiosPromise<WidgetDto> {
            return localVarFp.putWidget(widgetId, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContentApi - object-oriented interface
 * @export
 * @class ContentApi
 * @extends {BaseAPI}
 */
export class ContentApi extends BaseAPI {
    /**
     * deletes a widget
     * @param {string} widgetId an id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public deleteWidget(widgetId: string, options?: AxiosRequestConfig) {
        return ContentApiFp(this.configuration).deleteWidget(widgetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get assets from asset store
     * @param {string} assetId an id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public getAssets(assetId: string, options?: AxiosRequestConfig) {
        return ContentApiFp(this.configuration).getAssets(assetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * gets a widget
     * @param {string} widgetId an id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public getWidget(widgetId: string, options?: AxiosRequestConfig) {
        return ContentApiFp(this.configuration).getWidget(widgetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get widgets without a parent.
     * @param {number} [limit] limits the returned results
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public getWidgets(limit?: number, options?: AxiosRequestConfig) {
        return ContentApiFp(this.configuration).getWidgets(limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * performs a form upload
     * @param {File} file The file to upload.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public postFormUpload(file: File, options?: AxiosRequestConfig) {
        return ContentApiFp(this.configuration).postFormUpload(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * performs an upload
     * @param {Array<UploadDto>} body upload with meta data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public postUploads(body: Array<UploadDto>, options?: AxiosRequestConfig) {
        return ContentApiFp(this.configuration).postUploads(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * creates a root widget.
     * @param {WidgetDto} body widget to store
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public postWidget(body: WidgetDto, options?: AxiosRequestConfig) {
        return ContentApiFp(this.configuration).postWidget(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * updates a root widget.
     * @param {string} widgetId an id
     * @param {WidgetDto} body widget to store
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public putWidget(widgetId: string, body: WidgetDto, options?: AxiosRequestConfig) {
        return ContentApiFp(this.configuration).putWidget(widgetId, body, options).then((request) => request(this.axios, this.basePath));
    }
}


