import "./widget-cite/widget-cite";
import "./widget-countdown/widget-countdown";
import "./widget-gallery/widget-gallery";
import "./widget-image/widget-image";
import "./widget-page/widget-page";
import "./widget-richtext/widget-richtext";
import "./widget-video/widget-video";
import "./widget-empty/widget-empty";

import globalState from "../../global-state";
import {contentApi} from "../../api/api-wrapper";
import {postbox} from "../../components/util/postbox";
import {Context} from "@profiscience/knockout-contrib-router";
import {WidgetDto, WidgetDtoTypeEnum} from "../../api/generated";
import {createConfirmModal} from "../../components/elements/modal/modal";
import i18nextko from "../../bindings/i18nko";

export class EditorViewModelContext extends Context {
    widget: WidgetDto;
    params: {
        id: number;
    };
}

export function saveWidget(widget: WidgetDto) {
    globalState.loading(true);

    const savePromise = widget.id ? contentApi.putWidget(widget.id, widget) : contentApi.postWidget(widget);
    return savePromise.then(response => {
        postbox.addSuccess('editor.success.submit');

        return Promise.resolve(response.data);
    })
        .catch(err => {
            postbox.addError('editor.error.submit');

            return Promise.reject(err);
        })
        .finally(() => globalState.loading(false));
}

export function deleteWidget(widget: WidgetDto) {
    return createConfirmModal(
        i18nextko.t("editor.modal.delete.body"),
        i18nextko.t("editor.modal.delete.header"),
        i18nextko.t("global.delete"),
        i18nextko.t("global.cancel"))
        .then(() => {
            globalState.loading(true);

            return contentApi.deleteWidget(widget.id).then(
                () => {
                    postbox.addSuccess('editor.success.delete');

                    return Promise.resolve();
                })
                .catch(err => {
                    postbox.addError('editor.error.delete');

                    return Promise.reject(err);
                })
                .finally(() => globalState.loading(false));
        });
}

export function loadWidget(id: string): Promise<WidgetDto> {
    return contentApi.getWidget(id)
        .then(response => {
            const widget = response.data;
            if (widget.type !== WidgetDtoTypeEnum.Page) {
                return Promise.reject("invalid root widget type");
            }

            return widget;
        });
}

