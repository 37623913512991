class MenuViewModel {

}

export default {
    viewModel: MenuViewModel,
    template: <string>require('./menu.html')
};


