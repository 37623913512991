import * as ko from 'knockout';
import {AllBindings} from 'knockout';

const knockoutMapping = require('knockout-mapping');

// see https://getbootstrap.com/docs/4.6/components/carousel/#carouselcycle
ko.bindingHandlers.carousel = {
    init: function (element, valueAccessor, allBindingsAccessor: AllBindings) {
        const options = knockoutMapping.toJS( ko.utils.unwrapObservable(valueAccessor()) );
        const $element = $(element);
        const ride = $element.data('ride');
        if(ride === 'carousel') {
            // Enable autoplay for carousel - which is not working via data attributes
            // @ts-ignore
            $element.carousel('cycle');
        }
    },
    update: function (element, valueAccessor) {
        // for now carousels are not observables
    }
};

