import * as ko from "knockout"
import {components, Subscribable} from "knockout"
import * as jQuery from "jquery"
import ensureObservable from "../../util/ensure-observable"
import Config = components.Config;
import ComponentInfo = components.ComponentInfo;


interface ModalViewConfig {
    modalBodyComponent: string,
    headerLabel: string | Subscribable<string>
    closeLabel: string | Subscribable<string>
    confirmLabel: string | Subscribable<string>
    params: any
    container?: string
}

export interface ModalViewCallback {
    getResolveData?: () => Promise<any>,
    getRejectData?: () => Promise<any>
}

export interface ModalBodyComponentContext {
    callback: ModalViewCallback,
    params: any
}

class GenericConfirmModalViewModel {
    constructor(public params: {
        callback: ModalViewCallback
    }) {
    }
}

class ModalViewModel {
    public visible = ko.observable(false);
    public modalBodyComponent = ko.observable("");
    public headerLabel: Subscribable = ko.observable("");
    public closeLabel: Subscribable = ko.observable("");
    public confirmLabel: Subscribable = ko.observable("");

    private callback: ModalViewCallback = {
        getResolveData: () => Promise.resolve(),
        getRejectData: () => Promise.resolve()
    };

    private result: any;
    private success = false;

    constructor(private params: {
        modalConfig: ModalViewConfig,
        resolve: (value?: any) => void,
        reject: (value?: any) => void
    }) {
        this.modalBodyComponent(params.modalConfig.modalBodyComponent);
        this.headerLabel = ensureObservable(params.modalConfig.headerLabel);
        this.closeLabel = ensureObservable(params.modalConfig.closeLabel);
        this.confirmLabel = ensureObservable(params.modalConfig.confirmLabel);
        this.visible(true);
        this.visible.subscribe(
            (newValue) => {
                if (!newValue) {
                    this.hidden()
                }
            }
        )
    }

    public ok = () => {
        this.result = this.callback.getResolveData().then(
            (result) => {
                this.success = true;
                this.visible(false);
                return result
            }
        )
    };

    public hidden = () => {
        if (!this.success) {
            if (this.callback.getRejectData != null) {
                this.params.reject(this.callback.getRejectData)
            } else {
                this.params.reject()
            }
        } else {
            this.params.resolve(this.result)
        }
    }
}

// noinspection JSUnusedGlobalSymbols
export function createConfirmModal<T>(
    modalBodyText: string | Subscribable<string>,
    headerLabel: string | Subscribable<string>,
    confirmLabel: string | Subscribable<string>,
    closeLabel: string | Subscribable<string>,
    container?: string
) {
    return createModal({
        modalBodyComponent: "genericConfirmModal",
        headerLabel: headerLabel,
        closeLabel: closeLabel,
        confirmLabel: confirmLabel,
        container: container,
        params: {
            modalBodyText: modalBodyText
        }
    })
}

export function createModal<T>(modalConfig: ModalViewConfig): Promise<T> {
    let id = "modal-" + new Date().getTime();
    let modal = jQuery("<div id=\"" + id + "\"></div>");
    if (modalConfig.container != null) {
        jQuery(modalConfig.container).append(modal)
    } else {
        jQuery("body").append(modal)
    }
    return new Promise<T>(
        (resolve, reject) => {
            ko.applyBindingsToNode(modal.get(0), {
                component: {
                    name: "genericModal",
                    params: {
                        modalConfig: modalConfig,
                        resolve: resolve,
                        reject: reject
                    }
                }
            }, null)
        }
    ).then(
        (value) => {
            // Remove the modal later. This needs to happen after the fadeout, so we
            // give it a second time.
            setTimeout(
                () => modal.remove(), 1000
            );
            return value
        }
    ).catch(
        (error) => {
            // Remove the modal later. This needs to happen after the fadeout, so we
            // give it a second time.
            setTimeout(
                () => modal.remove(), 1000
            );
            return Promise.reject(error)
        }
    )
}


// noinspection JSUnusedLocalSymbols
let component: Config = {
    viewModel: (params: any, componentInfo?: ComponentInfo) => {
        return new ModalViewModel(params)
    },
    template: <string>require('./modal.html')
};

if (!ko.components.isRegistered('genericModal')) {
    ko.components.register('genericModal', component)
}


// noinspection JSUnusedLocalSymbols
let componentGenericConfirm: Config = {
    viewModel: (params: any, componentInfo?: ComponentInfo) => {
        return new GenericConfirmModalViewModel(params)
    },
    template: <string>require('./genericConfirmModalBody.html')
};

if (!ko.components.isRegistered('genericConfirmModal')) {
    ko.components.register('genericConfirmModal', componentGenericConfirm);
}
