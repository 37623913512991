import * as ko from 'knockout';
import {Router} from '@profiscience/knockout-contrib-router';
import {
    getRouterForBindingContext,
    isActivePath,
    log,
    traversePath
} from '@profiscience/knockout-contrib-router/dist/utils';

export var activePathBinding = {
    init: function (el, valueAccessor, allBindings, viewModel, bindingCtx) {
        const activePathCSSClass = allBindings.get('pathActiveClass') || Router.config.activePathCSSClass;

        Router.initialized
            .then(() => {
                let _a;
                const router = getRouterForBindingContext(bindingCtx);
                const route = ko.pureComputed(() => traversePath(router, ko.unwrap(valueAccessor())));
                ko.applyBindingsToNode(el, {
                        css: (_a = {},
                            _a[activePathCSSClass] = ko.pureComputed(() => isActivePath(route())),
                            _a)
                    },
                    bindingCtx);
            })
            .catch(err => log.error('Error initializing activePath binding', err));
    }
};
ko.bindingHandlers.reactiveActivePath = activePathBinding;
