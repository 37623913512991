import {Context} from "@profiscience/knockout-contrib-router";
import {contentApi} from "../../api/api-wrapper";
import {config} from "../../utils/clientConfigWrapper";
import {WidgetDto} from "../../api/generated";

class ViewModelContext extends Context {
    homePageWidget: WidgetDto;
}

class ViewModel {
    homePageWidget: WidgetDto;

    constructor(ctx: ViewModelContext) {
        this.homePageWidget = ctx.homePageWidget;
    }
}

export default <KnockoutLazyComponentDefinition>{
    viewModel: ViewModel,
    template: require('./home.html'),
    name: "home",
    loader: (ctx: ViewModelContext) => {
        return Promise.all([
            contentApi.getWidget(config.widgetIds.home)
                .then(response => ctx.homePageWidget = response.data)
                .catch(err => {
                    console.error(err.message);
                    return Promise.reject(err);
                })
        ]);
    }
};