import * as ko from 'knockout';
import * as $ from "jquery";
import {AllBindings, BindingHandler} from "knockout";

/**
 * Enterkey binding parameters.
 */
interface BindingContext {
    callback: Function,
    args: Array<any>
}

/**
 * A custom binding handler for 'Enter' keypress events to execute a callback function and pass an array of parameters to the callback.
 * eg: <input type="text" data-bind="textInput:itemName, enterkey: { 'callback': addCategoryItem, args: [tripId, categoryId] }">
 */
class EnterkeyBinding implements BindingHandler {
    init (element: Node, valueAccessor: () => BindingContext, allBindings: AllBindings, viewModel: any) {
        const params = ko.unwrap(valueAccessor());
        const callback = params.callback;
        const args = params.args ? params.args : [];
        $(element).on( 'keypress', function (event) {
            const keyCode = (event.which ? event.which : event.keyCode);
            if (keyCode === 13) {
                callback.apply(viewModel, args);
                return false;
            }
            return true;
        });
    }
}

export default new EnterkeyBinding();