/**
 * See https://github.com/grofit/knockout.tab
 */

const $ = require("jquery");
(function(knockout){
	knockout.tabs = [];
	knockout.tabs.show = function(tabSelector) {
		$(tabSelector).trigger('click');
	};
			
	var setupTabEntry = function(tabElement, contentElement, isVisible, groupName, options) {
		var tabEntry = {
			IsVisible: knockout.observable(isVisible),
			TabElement: tabElement,
			ContentElement: contentElement
		};

		var visibilitySubscription = function(visibility) {
			if(visibility) 
			{
				if(options.activeClass)
				{ $(tabEntry.TabElement).addClass(options.activeClass); }
				if(options.inactiveClass)
				{ $(tabEntry.TabElement).removeClass(options.inactiveClass); }
				
				if(options.onVisible)
				{ options.onVisible(tabEntry, groupName, options); }
				else
				{
					$(tabEntry.ContentElement).slideDown(options.duration, function() {
						return options.onShow(tabElement, contentElement);
					});
				}
			}
			else
			{
				if(options.activeClass)
				{ $(tabEntry.TabElement).removeClass(options.activeClass); }
				if(options.inactiveClass)
				{ $(tabEntry.TabElement).addClass(options.inactiveClass); }
				
				if(options.onHidden)
				{ options.onHidden(tabEntry, groupName, options); }
				else
				{
					$(tabEntry.ContentElement).slideUp(options.duration, function() {
						return options.onHide(tabElement, contentElement);
					});
				}
			}
		};
		
		visibilitySubscription(isVisible);
		tabEntry.IsVisible.subscribe(visibilitySubscription);
		knockout.tabs[groupName].push(tabEntry);
		return tabEntry;
	};
	
	var setupTabInteractions = function(tabEntry, groupName, options) {
		var onChangingTabProxy = function() {
			var groupedTabEntries = knockout.tabs[groupName];
			groupedTabEntries.forEach(function(groupedTabEntry, index){
				if(tabEntry.ContentElement == groupedTabEntry.ContentElement)
				{
					// close tab on click if it's opened
					if(groupedTabEntry.IsVisible()) {
						groupedTabEntry.IsVisible(false);
					} else {
						groupedTabEntry.IsVisible(true);
					}
				}
				else
				{ groupedTabEntry.IsVisible(false); }				
			});
			
			if(options.onTabChanged)
			{ options.onTabChanged(tabEntry, groupName, options); }
		};
				
		tabEntry.TabElement.onclick = onChangingTabProxy;
	}
	const defaults = {
		activeClass: 'tab--active',
		inactiveClass: 'tab--inactive',
		duration: 400,
		onShow: function (tabElement, contentElement) {},
		onHide: function (tabElement, contentElement) {},
	};

	knockout.bindingHandlers.tab = {
		init: function(element, valueAccessor, allBindingsAccessor, viewModel) {
			var allBindings = allBindingsAccessor();
			var tabBindings = allBindings.tab;
			
			var contentSelector = knockout.unwrap(tabBindings.content);
			var tabGroup = knockout.unwrap(tabBindings.group);
			var options = knockout.unwrap(tabBindings.options) || {};
			options = $.extend(true, {}, defaults, options);
						
			if(!contentSelector)
			{
				console.log("Unable to setup tabs due to no content being set");
				return;
			}
			
			if(!tabGroup) { tabGroup = "default"; }
			if(!knockout.tabs[tabGroup]) { knockout.tabs[tabGroup] = []; }
			
			var isVisible = (options.isDefault) ? true : false;
			var contentElement = $(contentSelector)[0];
			
			var tabEntry = setupTabEntry(element, contentElement, isVisible, tabGroup, options);
			setupTabInteractions(tabEntry, tabGroup, options);
		}
	};

})(typeof exports === 'undefined' ? this["ko"] : require("knockout"));
