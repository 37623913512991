import {config} from "../../../utils/clientConfigWrapper";
import globalState from '../../../global-state';

class FooterViewModel {

    /**
     * Include script tag - webpack removes script tags within components, localizations do not work as well.
     */
    public get campingInfoWidget() {
        // dct = 19419 , dck = 19421
        const widgetId = config.site == 'dct' ? 19419 : 19421;
        const language = globalState.language();
        return `<script src='https://www.camping.info/ratingswidget.js?id=${widgetId}&lang=${language}&v=3' async></script>`
    }
}

export default {
    viewModel: FooterViewModel,
    template: <string>require('./footer.html')
};
