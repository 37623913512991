import * as ko from "knockout";
import {components} from "knockout";
import * as common from "../_common";
import ComponentInfo = components.ComponentInfo;
import Config = components.Config;


/**
 * Display parameters for widget.
 */
interface WidgetEmptyViewModelParams extends common.WidgetComponentCompositionContext {

}

/**
 * Shows an image. Only a stub at the moment.
 */
class WidgetEmptyViewModel extends common.WidgetComponentCompositionModel<common.WidgetContent> {

    public setWidgetType: Function;

    constructor(ctx: WidgetEmptyViewModelParams) {
        super(ctx);
        this.setWidgetType = ctx.setWidgetType;
    }

    public editComponent() {
        return null;
    }

}

let componentWidgetEmpty: Config = {
    viewModel: (params: WidgetEmptyViewModelParams, componentInfo?: ComponentInfo) => new WidgetEmptyViewModel(params),
    template: <string>require('./widget-empty.html')
};

ko.components.register("widget-empty", componentWidgetEmpty);

