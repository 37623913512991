import * as ko from "knockout"
import {Observable, ObservableFunctions} from "knockout"
import * as $ from "jquery";
import "select2"

const bindingName = 'select2';

// noinspection JSUnusedLocalSymbols
function triggerChangeQuietly(element: HTMLElement, binding: ObservableFunctions<any>) {
    const isObservable = ko.isObservable(binding);
    let originalEqualityComparer;
    if (isObservable) {
        originalEqualityComparer = binding.equalityComparer;
        binding.equalityComparer = function () {
            return true;
        };
    }
    $(element).trigger('change');
    if (isObservable) {
        binding.equalityComparer = originalEqualityComparer;
    }
}

// noinspection JSUnusedLocalSymbols
function init(element: HTMLElement, valueAccessor: Observable<any>, allBindingsAccessor: Observable<any>,
              viewModel, bindingContext) {
    $(element).select2(valueAccessor());

    ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
        $(element).select2('destroy');
    });
}

ko.bindingHandlers[bindingName] = {
    init: function () {
        // Delay to allow other binding handlers to run, as this binding handler depends on options bindings
        const args = arguments;
        setTimeout(function () {
            init.apply(null, args);
        }, 0);
    },
    update: function (element, valueAccessor, allBindingsAccessor) {
        ko.unwrap(allBindingsAccessor.get("options"));
        ko.unwrap(allBindingsAccessor.get("value"));
        $(element).trigger('change');
    }
};


