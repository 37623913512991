import {config} from "../../../utils/clientConfigWrapper";
import i18next from "i18next";
import i18nextko from "../../../bindings/i18nko";
import {escape} from "querystring";


class BookingViewModel {

    public get email(): string {
        return config.bookingEmail;
    }

    public get subject() {
        return i18nextko.t('booking.email.subject')().replace(/<br\s*\/?>/mg,"%0D%0A");
    }

    public get body() {
        return i18next.t('booking.email.body');
    }

    public get href(): string {
        return `mailto:${this.email}?subject=${encodeURI(this.subject)}&body=${encodeURI(this.body)}`;
    }
}

export default {
    viewModel: BookingViewModel,
    template: <string>require('./booking.html')
};
