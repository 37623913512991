import * as ko from "knockout";
import {Observable, Subscribable} from "knockout";

export function ensureObservable<T>(value: T | Subscribable<T>): Subscribable<T> {
    if (ko.isSubscribable(value)) {
        return value;
    } else {
        return ko.observable(value);
    }
}

export default ensureObservable;