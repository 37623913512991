import * as $ from "jquery";
import * as ko from 'knockout';
import {BindingHandler, Observable} from "knockout";

/**
 * ScrollToError binding parameters.
 */
interface ScrollToErrorBindingContext {
    /**
     * The current page has errors.
     */
    hasErrors: Observable<boolean> | boolean,
    /**
     * Duration of the scrolling animation (jquery). Defaults to 'fast'.
     */
    duration?: JQuery.Duration,
    /**
     * The easing of the scrolling animation (jquery). Defaults to 'swing'.
     */
    easing?: string,
    /**
     * The element class, that identifies an element with an validation error. Defaults to "validationMessage".
     */
    elementClass?: string
}

/**
 * A binding handler that scrolls to the first element with an validation error. Should be set on a container of
 * form elements (eg: a form).
 */
class ScrollToErrorBinding implements BindingHandler {

    update(element: Node, valueAccessor: () => ScrollToErrorBindingContext) {
        const params = valueAccessor();

        const hasErrors = ko.unwrap(params.hasErrors);
        if (hasErrors) {
            const $failedElement = $(`.${params.elementClass || "validationMessage"}:visible`).first();
            $failedElement.each(function () {
                $('html').animate({
                    scrollTop: $failedElement.offset().top - 200
                }, params.duration || 'fast', params.easing || 'swing');
            });
        }
    }
}

export default new ScrollToErrorBinding();