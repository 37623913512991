import * as ko from 'knockout';
import * as $ from "jquery";
import {AllBindings, BindingHandler} from "knockout";

/**
 * Enterkey binding parameters.
 */
interface BindingContext {
    callback: Function,
    args: Array<any>
}

/**
 * A custom binding handler for 'blur' events to execute a callback function and pass an array of parameters to the callback.
 * eg: <input type="text" data-bind="textInput:itemName, blur: { 'callback': saveCategoryItem, args: [tripId, categoryId] }">
 */
class BlurBinding implements BindingHandler {
    init (element: Node, valueAccessor: () => BindingContext, allBindings: AllBindings, viewModel: any) {
        const params = ko.unwrap(valueAccessor());
        const callback = params.callback;
        const args = params.args ? params.args : [];
        $(element).on( 'blur', function (event) {
            callback.apply(viewModel, args);
            return false;
        });
    }
}

export default new BlurBinding();