import {WidgetDto, WidgetDtoTypeEnum} from "../../../api/generated";
import * as ko from "knockout";
import {components, Observable} from "knockout";
import * as common from "../_common";
import {autobind, observable} from "knockout-decorators";
import * as modal from "../../../components/elements/modal/modal";
import "knockout-sortable";
import i18nextko from "../../../bindings/i18nko";
import Config = components.Config;

/**
 * A widget containers shows child widgets in a layout. The layout determines
 * the markup structure and is defined as a the attribute "layout" in the widget
 * parameters.
 *
 * Placement of child widgets is done by the layout.
 */

/**
 * Display parameters for widget.
 */
interface WidgetComponentContainerViewModelParams extends common.WidgetComponentCompositionContext {
}

/**
 * Component model implementation. Delegates to the layout defined in the property "layout" of the widgets content.
 */
class WidgetComponentContainerViewModel extends common.WidgetComponentCompositionModel<common.WidgetContentContainer> {

    constructor(params: WidgetComponentContainerViewModelParams) {
        super(params);
        // Initialize before setting the object into the @observable annotated object.
        if (typeof this.widgetContent.layout === 'undefined') {
            observable({deep: true, expose: true})(this.widgetContent, "layout");
            this.widgetContent.layout = 'vertical';
        }
        if (typeof this.widgetContent.title === 'undefined') {
            observable({deep: false, expose: true})(this.widgetContent, "title");
            this.widgetContent.title = null;
        }
        if (typeof this.widgetContent.collapsible === 'undefined') {
            observable({deep: false, expose: true})(this.widgetContent, "collapsible");
            this.widgetContent.collapsible = false;
        }
    }

    public layoutComponentForPage = ko.pureComputed(
        () => 'widget-container-layout-' + this.widgetContent.layout
    );

    public editComponent() {
        return "widget-container-edit";
    }

}

/**
 * Layout parameters.
 */
interface WidgetComponentContainerLayoutContext extends common.WidgetComponentCompositionContext {
    widgetContent: common.WidgetContentContainer;
}


/**
 * Layout view model. Currently, there is only one layout view model implementation as
 * this is sufficient for the layouts currently defined. Other implementations could be
 * added as needed using the component definition.
 *
 * Layouts can be fixed or fluid. A fixed layout has a defined number of children, whereas
 * a fluid layout as a flexible number of children (0..n). This is currently controlled
 * by the template.
 */
class WidgetComponentContainerLayoutViewModel extends common.WidgetComponentCompositionModel<common.WidgetContentContainer> {

    constructor(params: WidgetComponentContainerLayoutContext) {
        super(params);
        console.debug("widget params", params);
    }

    /**
     * For a fixed layout, get the widget at the given index. If there is no widget
     * at the given index, create an empty one.
     */
    public widgetForIndex(index: number) {
        if (!this.widget.children) {
            this.widget.children = [];
        }
        if (!this.widget.children[index]) {
            this.widget.children[index] = {
                content: {},
                type: WidgetDtoTypeEnum.Empty,
                children: []
            };
        }
        return this.widget.children[index];
    }

    /**
     * Add a widget to the list of children. Typically used by fluid layouts that
     * can add additional children.
     */
    @autobind
    public addWidget() {
        modal.createModal(
            {
                modalBodyComponent: "widget-selection-modal-body",
                headerLabel: this.i18n("widget.widget.modal.title"),
                closeLabel: this.i18n("widget.modal.button.close"),
                confirmLabel: this.i18n("widget.modal.button.add"),
                params: {}
            })
            .then((widgetType: WidgetDtoTypeEnum) => {
                if (!this.widget.children) {
                    this.widget.children = []
                }
                this.widget.children.push(
                    {
                        content: {},
                        type: widgetType,
                        children: []
                    }
                )
            })
            .catch(err => console.debug('add widget canceled', err));
    }

    /**
     * Remove the widget with the given index.
     */
    public removeWidget(index: number) {
        return () => {
            if (!this.widget.children) {
                this.widget.children = [];
            }
            this.widget.children.splice(index, 1);
        }
    }

    /**
     * Remove the widget with the given index from fixed layout.
     */
    public removeWidgetFixed(index: number) {
        return () => {
            if (!this.widget.children) {
                this.widget.children = [];
            }
            this.widget.children.splice(index, 1, {
                content: {},
                type: WidgetDtoTypeEnum.Empty,
                children: []
            });
        }
    }

    public editComponent() {
        return "widget-container-edit";
    }
}

/**
 * Parameter definition for a placeholder.
 */
interface WidgetComponentPlaceholderContext {
    /**
     * The widget to render.
     */
    widget: WidgetDto;

    /**
     * A function passed to the placeholder to remove the widget from the parent.
     * If the parent layout does not support removing, this parameter is undefined.
     */
    removeMe(): void;

    /**
     * For a fluid layout widgets can be repositioned and this property is true.
     * For a fixed layout that typically does not support reordering, it is false.
     */
    dragable: boolean;

    /**
     * A flag if the current page is in editing mode. Only show the editing
     * UX when this is true.
     */
    editing: Observable<boolean>;
}


/**
 * A placeholder is the component responsible for rendering a child widget.
 * The placeholders are put into the DOM by the layout and it is the placeholders
 * responsibility to (1) allow to set the widget type if this is currently not set
 * and (2) delegate rendering to the widget component.
 */
class WidgetComponentPlaceholderViewModel {
    /**
     * The widget to render
     **/
    public widget: WidgetDto;
    /**
     * A function passed to the placeholder to remove the widget from the parent
     * If the parent layout does not support removing, this parameter is undefined
     **/
    public removeMe: Function;
    /**
     * For a fluid layout widgets can be repositioned and this property is true
     * For a fixed layout that typically does not support reordering, it is false
     **/
    public dragable: boolean;
    /**
     * A flag if the current page is in editing mode. Only show the editing
     * UX when this is true
     **/
    public editing: Observable<boolean>;


    constructor(params: WidgetComponentPlaceholderContext) {
        this.widget = params.widget;
        if (!this.widget.type) {
            observable({deep: true, expose: true})(this.widget, "type");
            this.widget.type = WidgetDtoTypeEnum.Empty;
        }
        this.removeMe = params.removeMe;
        this.dragable = params.dragable;
        this.editing = params.editing;
    }

    public componentForWidget = ko.pureComputed(() => common.typeComponentRegistry.get(this.widget.type));


    @autobind
    public setWidgetType() {
        modal.createModal(
            {
                modalBodyComponent: "widget-selection-modal-body",
                headerLabel: this.i18n("widget.widget.modal.title"),
                closeLabel: this.i18n("widget.modal.button.close"),
                confirmLabel: this.i18n("widget.modal.button.add"),
                params: {}
            })
            .then((widgetType: WidgetDtoTypeEnum) => {
                this.widget.type = widgetType;
                this.widget.content = {};
            })
            .catch(err => console.debug("set widget canceled", err));
    }

    public i18n(key: String, options?: object) {
        return i18nextko.t(key, options);
    }
}


class WidgetComponentContainerEditViewModel extends common.WidgetComponentEditModel<common.WidgetContentContainer> {

    @observable({deep: false, expose: true})
    public selectedLayout: string = null;

    @observable({deep: false, expose: true})
    public title: string;

    @observable({deep: false, expose: true})
    public collapsible: boolean;

    public layouts = ko.observableArray([
        'vertical',
        '2-columns',
        '3-columns',
    ]);

    constructor(ctx: common.WidgetComponentEditContext) {
        super(ctx);
        this.selectedLayout = this.widgetContent.layout;
        this.title = this.widgetContent.title;
        this.collapsible = this.widgetContent.collapsible;

        ctx.callback.getResolveData = () => {
            this.widgetContent.layout = this.selectedLayout;
            this.widgetContent.title = this.title;
            this.widgetContent.collapsible = this.collapsible;
            return Promise.resolve();
        };
    }

    @autobind
    public optionsText(option: any) {
        return this.i18n('widget.container.modal.layout.options.' + option);
    }
}


class WidgetSelectionModalBodyComponentViewModel {

    public widgetTypes = ko.observableArray([]);

    @observable({deep: false, expose: true})
    public selectedWidgetType: WidgetDtoTypeEnum = null;

    constructor(ctx: modal.ModalBodyComponentContext) {
        common.typeComponentRegistry.forEach((value, key) => {
            if (key !== 'PAGE' && key !== 'EMPTY') {
                this.widgetTypes.push({text: i18nextko.t(`widget.widget.modal.types.${value}`), value: key});
            }
        });
        ctx.callback.getResolveData = () => Promise.resolve(this.selectedWidgetType);
    }

    public i18n(key: String, options?: object) {
        return i18nextko.t(key, options);
    }
}

/**
 * Component registration for the placeholder.
 */
let componentWidgetPagePlaceholder: Config = {
    viewModel: (params: WidgetComponentPlaceholderContext) =>
        new WidgetComponentPlaceholderViewModel(params),
    template: <string>require('./placeholder.html')
};

ko.components.register("widget-placeholder", componentWidgetPagePlaceholder);


let componentWidgetSelectionModalBody: Config = {
    viewModel: (params: modal.ModalBodyComponentContext) =>
        new WidgetSelectionModalBodyComponentViewModel(params),
    template: <string>require('./widget-selection-modalbody.html')
};

ko.components.register("widget-selection-modal-body", componentWidgetSelectionModalBody);

/**
 * Component registration for the widget and the widget editor.
 */
const componentWidgetContainer: Config = {
    viewModel: (params: WidgetComponentContainerViewModelParams) =>
        new WidgetComponentContainerViewModel(params),
    template: <string>require('./widget-container.html')
};

ko.components.register("widget-container", componentWidgetContainer);

const componentWidgetContainerEdit: Config = {
    viewModel: (params: common.WidgetComponentEditContext) =>
        new WidgetComponentContainerEditViewModel(params),
    template: <string>require('./widget-container-edit.html')
};

ko.components.register("widget-container-edit", componentWidgetContainerEdit);

/**
 * Component registration for layouts.
 */
ko.components.register('widget-container-layout-hero', {
    viewModel: (params: WidgetComponentContainerLayoutContext) =>
        new WidgetComponentContainerLayoutViewModel(params),
    template: <string>require('./layout-hero.html')
});

ko.components.register('widget-container-layout-left-column', {
    viewModel: (params: WidgetComponentContainerLayoutContext) =>
        new WidgetComponentContainerLayoutViewModel(params),
    template: <string>require('./layout-left-column.html')
});

ko.components.register('widget-container-layout-vertical', {
    viewModel: (params: WidgetComponentContainerLayoutContext) =>
        new WidgetComponentContainerLayoutViewModel(params),
    template: <string>require('./layout-vertical.html')
});

ko.components.register('widget-container-layout-2-columns', {
    viewModel: (params: WidgetComponentContainerLayoutContext) =>
        new WidgetComponentContainerLayoutViewModel(params),
    template: <string>require('./layout-2-columns.html')
});

ko.components.register('widget-container-layout-3-columns', {
    viewModel: (params: WidgetComponentContainerLayoutContext) =>
        new WidgetComponentContainerLayoutViewModel(params),
    template: <string>require('./layout-3-columns.html')
});
